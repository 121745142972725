import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Who we work with`}</h1>
    <p>{`As a nonprofit, `}<a parentName="p" {...{
        "href": "/about"
      }}>{`CompDem`}</a>{` provides `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{` to the public by:`}</p>
    <ul>
      <li parentName="ul">{`working with `}<a parentName="li" {...{
          "href": "/government",
          "title": "government"
        }}>{`government`}</a>{` to bring the public's voice directly into decision making processes`}</li>
      <li parentName="ul">{`working with nonprofits, NGOs and `}<a parentName="li" {...{
          "href": "/newsrooms"
        }}>{`non profit media organizations`}</a></li>
      <li parentName="ul">{`working with `}<a parentName="li" {...{
          "href": "/academia"
        }}>{`academic`}</a>{` institutions`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      